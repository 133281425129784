<template>
    <v-card class="action-bar actionNavColor d-flex justify-center align-center px-2">
      <v-row class="d-flex justify-space-around align-center px-3">
          
          <v-col @click="changePage('apps')" cols="2" class="d-flex flex-column justify-center align-center">
            <v-icon :class="active == 'apps'? 'onActionNav--text':''" size="27">mdi-dots-grid</v-icon> 
          </v-col>
          <v-col @click="changePage('')" cols="2" class="d-flex flex-column justify-center align-center pa-0">
            <v-icon :class="active == 'home'? 'onActionNav--text':''" size="26">mdi-storefront-outline</v-icon>
          </v-col>
          <v-col @click="changePage('basket')" cols="2" class="d-flex flex-column justify-center align-center">
            <v-badge v-if="countBasket.length != 0" bordered color="error" :content="countBasket.length" offset-x="20" offset-y="10"></v-badge>
            <v-icon size="26">mdi-cart-outline</v-icon>
          </v-col>
          <v-col @click="changePage('profile')" cols="2" class="d-flex flex-column justify-center align-center">
            <v-icon :class="active == 'profile'? 'onActionNav--text':''" size="30">mdi-account-outline</v-icon>
          </v-col>
      </v-row>
    </v-card>
</template>
<style lang="scss" scoped>
.v-sheet.v-card{
    border-top-left-radius: 34px;
    border-top-right-radius: 34px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.action-bar{
    width: 100%;
    height: 56px;
    position: fixed;
    bottom: 4px;
    right: 0;
    bottom: 0;
    border-top:1px solid #4141411F;
    z-index: 1;
}
.action-icon{
  color: #414141;
}
</style>

<script>
import router from '@/router'
import { mapState } from 'vuex'
export default {
  data: () => ({
    Home: true,
    basket: false
  }),
  computed: {
    ...mapState({
    countBasket: state => state.basket.basketCount
    }),
    active () {
        const url = this.$router.history.current.name
        if (url == "product_detail" || url == "search") {
          return "home"
        }
        if (url == "subordinate") {
          return "home"
        }
        return url
    }
  },
  methods: {
    changePage (page) {
      this.basket = true
      router.push(`/${page}`)
    }
  }
}
</script>